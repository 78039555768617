import React from "react"

export default function ColorCircleSVG({ className = 'icon' }): React.JSX.Element {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h32v32H0z"></path>
                <path
                    fill="var(--primaryIconColor)"
                    fillRule="nonzero"
                    d="M16 0c6.075 0 11 4.925 11 11 0 .256-.009.51-.026.762A10.99 10.99 0 0132 21c0 6.075-4.925 11-11 11-1.8 0-3.5-.432-5-1.2-1.5.767-3.2 1.2-5 1.2-6.075 0-11-4.925-11-11 0-3.873 2.001-7.278 5.027-9.238A10.821 10.821 0 015 11C5 4.925 9.925 0 16 0zm10.597 13.952l-.027.105a11.023 11.023 0 01-4.597 6.181 10.978 10.978 0 01-3.975 9.249 9 9 0 008.6-15.535zm-21.196.001l-.03.024a9 9 0 008.632 15.51 10.975 10.975 0 01-3.977-9.249 11.021 11.021 0 01-4.625-6.285zm14.596 7.297l-.159.062A10.977 10.977 0 0116 22c-1.41 0-2.758-.265-3.997-.749L12 21c0 3.12 1.588 5.87 4 7.484a8.992 8.992 0 003.997-7.234zM16 13.516l-.033.021a9.007 9.007 0 00-3.779 5.618c1.157.542 2.449.845 3.811.845s2.654-.303 3.81-.844a9.008 9.008 0 00-3.809-5.64zM11 12a8.966 8.966 0 00-3.81.844 9 9 0 003.213 5.204c.61-2.2 1.887-4.122 3.599-5.535A8.988 8.988 0 0011 12zm10 0c-1.053 0-2.064.18-3.003.513a11.002 11.002 0 013.602 5.534 8.997 8.997 0 003.212-5.202A8.955 8.955 0 0021 12zM16 2a9 9 0 00-8.997 8.75A10.951 10.951 0 0111 10c1.8 0 3.5.433 5 1.2 1.5-.768 3.2-1.2 5-1.2 1.41 0 2.758.265 3.997.749L25 11a9 9 0 00-9-9z"
                ></path>
            </g>
        </svg>
    )
}
