import React from "react"

export default function ColorCirclePaintedSVG({ className = 'icon' }): React.JSX.Element {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 22.342 22.342"
            xmlSpace="preserve"
        >
            <g transform="translate(-132.387 -28.047)">
                <path
                    fill="#0f0"
                    stroke="none"
                    strokeWidth="0.692"
                    d="M146.92 43.102a6.913 6.913 0 01-6.917 6.917 6.913 6.913 0 01-6.917-6.917 6.915 6.915 0 016.917-6.917 6.915 6.915 0 016.916 6.917z"
                    color="#000"
                ></path>
                <path
                    fill="red"
                    strokeWidth="1.45"
                    d="M143.96 29.342a7.247 7.247 0 00-3.608 13.535c-.006-.114-.017-.225-.017-.34a7.247 7.247 0 0110.858-6.284 7.242 7.242 0 00-7.233-6.913z"
                ></path>
                <path
                    fill="#f0f"
                    fillOpacity="1"
                    strokeWidth="1.383"
                    d="M146.624 35.674a6.914 6.914 0 00-6.917 6.917c0 .11.011.217.016.325a6.914 6.914 0 0010.359-5.995c0-.11-.011-.216-.017-.324a6.882 6.882 0 00-3.441-.923z"
                ></path>
                <path
                    fill="#00f"
                    strokeWidth="1.383"
                    d="M150.38 36.237c.006.108.016.215.016.324a6.914 6.914 0 01-10.36 5.996 6.908 6.908 0 006.902 6.592 6.914 6.914 0 003.442-12.912z"
                ></path>
                <path
                    fill="#0ff"
                    strokeWidth="1.383"
                    d="M139.998 42.395a6.911 6.911 0 003.44 5.662 6.899 6.899 0 003.444-5.66 6.886 6.886 0 01-3.442.918 6.882 6.882 0 01-3.442-.922z"
                ></path>
                <path
                    fill="#fff"
                    strokeWidth="1.196"
                    d="M143.74 36.268a5.97 5.97 0 00-2.987 5.176c0 .094.01.186.014.28a5.954 5.954 0 005.953.002c.004-.095.013-.188.013-.283a5.969 5.969 0 00-2.992-5.176z"
                ></path>
                <path
                    fill="#ff0"
                    strokeWidth="1.383"
                    d="M140.301 35.423a6.886 6.886 0 00-3.45.927 6.903 6.903 0 003.467 6.314c-.006-.107-.017-.214-.017-.323a6.904 6.904 0 013.456-5.987 6.882 6.882 0 00-3.456-.93z"
                ></path>
                <g
                    fill="none"
                    fillRule="evenodd"
                    transform="matrix(.6982 0 0 .6982 132.387 28.047)"
                >
                    <path d="M0 0h32v32H0z"></path>
                    <path
                        fill="#000"
                        fillRule="nonzero"
                        d="M16 0c6.075 0 11 4.925 11 11 0 .256-.009.51-.026.762A10.99 10.99 0 0132 21c0 6.075-4.925 11-11 11-1.8 0-3.5-.432-5-1.2-1.5.767-3.2 1.2-5 1.2-6.075 0-11-4.925-11-11 0-3.873 2.001-7.278 5.027-9.238A10.821 10.821 0 015 11C5 4.925 9.925 0 16 0zm10.597 13.952l-.027.105a11.023 11.023 0 01-4.596 6.181 10.978 10.978 0 01-3.975 9.249 9 9 0 008.6-15.535zm-21.196.001l-.03.024a9 9 0 008.632 15.51 10.975 10.975 0 01-3.977-9.249 11.021 11.021 0 01-4.625-6.285zm14.596 7.297l-.159.062A10.976 10.976 0 0116 22c-1.41 0-2.758-.265-3.997-.749L12 21c0 3.12 1.588 5.87 4 7.484a8.992 8.992 0 003.997-7.234zM16 13.516l-.033.021a9.007 9.007 0 00-3.779 5.618A8.955 8.955 0 0016 20a8.966 8.966 0 003.81-.844 9.008 9.008 0 00-3.809-5.64zM11 12a8.966 8.966 0 00-3.81.844 9 9 0 003.213 5.204c.61-2.2 1.887-4.122 3.599-5.535A8.988 8.988 0 0011 12zm10 0c-1.053 0-2.064.18-3.003.513a11.002 11.002 0 013.602 5.534 8.997 8.997 0 003.212-5.202A8.955 8.955 0 0021 12zM16 2a9 9 0 00-8.997 8.75A10.951 10.951 0 0111 10c1.8 0 3.5.433 5 1.2 1.5-.768 3.2-1.2 5-1.2 1.41 0 2.758.265 3.997.749L25 11a9 9 0 00-9-9z"
                    ></path>
                </g>
            </g>
        </svg>
    )
}
