export const STORAGE_SCHEMA_VERSION = 5
export const BOARD_API_VERSION = 8
export const RECOMMENDATIONS_API_VERSION = 1
export const BOOKMARKS_API_VERSION = 1
export const SOLVED_API_VERSION = 1
export const STATISTICS_API_VERSION = 1

export const BOARD_FADEIN_ANIMATION_DURATION_MS = 750
export const BOARD_WIN_ANIMATION_DURATION_MS = 1500
export const GAME_SLIDE_ANIMATION_DURATION_SECONDS = 0.35
export const COMMON_UI_ANIMATION_DURATION_SECONDS = 0.1
